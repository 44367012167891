// Testnet
const REACT_APP_ARB_PROVIDER = 'https://goerli-rollup.arbitrum.io/rpc';

const REACT_APP_OZL_CONTRACT = '0xAa5f138691768EDEaD231915eF7AB9370A708d70'; 
const REACT_APP_STORAGE_BEACON = '0xB9c24233Bfa2561C72a888522A68045814Efb3Dd'; 
const REACT_APP_OZERC1967PROXY = '0xCd9d0217D624B6E45cAEf2de453e7bF4DcFFE710'; 

// Mainnet
const REACT_APP_ARB_MAINNET_PROVIDER = 'https://arb1.arbitrum.io/rpc';

const REACT_APP_MAINNET_OZL_CONTRACT = '0x7D1f13Dd05E6b0673DC3D0BFa14d40A74Cfa3EF2';
const REACT_APP_MAINNET_STORAGE_BEACON = '0x53A64483Ad7Ca5169F26A8f796B710aCAdEb8f0C';
const REACT_APP_MAINNET_OZERC1967PROXY = '0x44e2e47039616b8E69dC153add52C415f22Fab2b';


module.exports = {
    REACT_APP_ARB_PROVIDER, 
    REACT_APP_OZL_CONTRACT,
    REACT_APP_STORAGE_BEACON,
    REACT_APP_OZERC1967PROXY,
    REACT_APP_ARB_MAINNET_PROVIDER,
    REACT_APP_MAINNET_OZL_CONTRACT,
    REACT_APP_MAINNET_STORAGE_BEACON,
    REACT_APP_MAINNET_OZERC1967PROXY
};
